import { createReducer } from "@reduxjs/toolkit";
import { login, logout } from "./actions";
import { ProfileState } from "./profile.dto";
import jwt from "jwt-decode";

const initialState: ProfileState = {
  profile: null,
  pending: false,
  error: false,
};

const GuestUsers = ['Lynn', 'Lorina', 'Nick', 'Henry', 'Brendon', 'Ellis', 'Bruce', 'Demo', 'Rebecca', 'Robert', 'CBRE', 'Jamie', 'King'];

export const profileReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(login.pending, (state) => {
      state.pending = true;
    })
    .addCase(login.fulfilled, (state, { payload }) => {
      state.pending = false;

      if (payload?.token) {
        const user: any = jwt(payload.token);
        
        state.profile = {
          firstName: user.given_name,
          lastName: user.family_name,
          jwt: payload.token,
          isGuestUser: GuestUsers.includes(user.given_name),
          associatedScans: calculateAssociatedScans(user.given_name),
        };
      }
    })
    .addCase(login.rejected, (state) => {
      state.pending = false;
      state.error = true;
    })
    .addCase(logout.pending, (state) => {
      state.pending = true;
    })
    .addCase(logout.fulfilled, (state) => {
      state.pending = false;
      state.profile = null;
    })
    .addCase(logout.rejected, (state) => {
      state.pending = false;
      state.error = true;
    });
});

const calculateAssociatedScans = (name: string) => {
  if (name === 'Nick' || name === 'Bruce') {
    return [323];
  }
  if (name === 'Henry' || name === 'Jamie') {
    return [240];
  }
  if (name === 'Brendon' || name === 'Ellis' || name === 'Demo') {
    return [358];
  }
  if (name === 'Rebecca') {
    return [368];
  }
  if (name === 'Robert') {
    return [368, 383]
  }
  if (name === 'CBRE') {
    return [369];
  }
  if (name === 'King') {
    return [383];
  }
  return [119];
}
