import { IHeader, IRow } from "../../../../models/benchmark/shared/Table";
import styles from "./Table.module.scss";
import Table, { ColumnsType } from "antd/es/table";
import React, {useState} from "react";
import {Input, Select} from "antd";

const ScreenTable = ({
  buildingId,
  title,
  headers,
  rows,
  activeOption, 
   doorPricePerHour,
   setDoorPricePerHour,
   panelPricePerHour,
   setPanelPricePerHour,
   dustingPricePerHour,
   setDustingPricePerHour,
   carpetCleaningFrequency,
   setCarpetCleaningFrequency,
   hardFloorCleaningFrequency,
   setHardFloorCleaningFrequency,
   carpetCleaningPricePerUnit,
   setCarpetCleaningPricePerUnit,
   hardFloorCleaningPricePerUnit,
   setHardFloorCleaningPricePerUnit,
}: {
  buildingId?: string | undefined;
  title?: string | null;
  headers?: IHeader[] | null;
  rows?: IRow[] | null;
  activeOption?: string | null;
  doorPricePerHour?: number,
  setDoorPricePerHour?: any,
  panelPricePerHour?: number,
  setPanelPricePerHour?: any,
  dustingPricePerHour?: number,
  setDustingPricePerHour?: any,
  carpetCleaningFrequency?: string,
  setCarpetCleaningFrequency?: any,
  hardFloorCleaningFrequency?: string,
  setHardFloorCleaningFrequency?: any,
  carpetCleaningPricePerUnit?: number,
  setCarpetCleaningPricePerUnit?: any,
  hardFloorCleaningPricePerUnit?: number,
  setHardFloorCleaningPricePerUnit?: any,
}) => {
    function capitalizeFirstLetter(val: any) {
        return String(val).charAt(0).toUpperCase() + String(val).slice(1);
    }

    const calculateHardfloorPrice = () => {
        let hardfloorFrequency = 12;

        switch (hardFloorCleaningFrequency) {
            case "daily":
                hardfloorFrequency = 5 * 4 * 12;
                break;
            case "weekly":
                hardfloorFrequency = 4 * 12;
                break;
            case "biweekly":
                hardfloorFrequency = 2 * 12;
                break;
            case "monthly":
                hardfloorFrequency = 12;
                break;
            case "quarterly":
                hardfloorFrequency = 4;
                break;
            case "biannually":
                hardfloorFrequency = 2;
                break;
            case "annually":
                hardfloorFrequency = 1;
                break;
        }

        return (1190.11 * hardfloorFrequency * (hardFloorCleaningPricePerUnit ?? 0))
    }

    const calculateCarpetPrice = () => {
        let carpetFrequency = 12;

        switch (carpetCleaningFrequency) {
            case "daily":
                carpetFrequency = 5 * 4 * 12;
                break;
            case "weekly":
                carpetFrequency = 4 * 12;
                break;
            case "biweekly":
                carpetFrequency = 2 * 12;
                break;
            case "monthly":
                carpetFrequency = 12;
                break;
            case "quarterly":
                carpetFrequency = 4;
                break;
            case "biannually":
                carpetFrequency = 2;
                break;
            case "annually":
                carpetFrequency = 1;
                break;
        }
        
        return (4950.22 * carpetFrequency * (carpetCleaningPricePerUnit ?? 0))
    }
    
  return (
    <>
      <div className={styles.mobileTableContainer}>
        {title && <h3>{title}</h3>}
        {buildingId == "262" && title == "Periodics" && (
            <div className={styles.btnContainer} style={{width: '100%'}}>
            </div>
        )}
        {buildingId == "262" && title == "Internal glass monthly cleaning" && (
            <div className={styles.btnContainer} style={{width: '100%'}}>
              <div style={{width: '33%'}}>
                <span>Door price per hour (£):</span>
                <Input style={{marginTop: '10px'}} value={doorPricePerHour} type={"number"} onChange={(e:any) => setDoorPricePerHour(e.target.value)}></Input>
              </div>
              <div style={{width: '33%'}}>
                <span style={{marginBottom: '5px'}}>Glass panel price per hour (£):</span>
                <Input style={{marginTop: '10px'}} value={panelPricePerHour} type={"number"} onChange={(e:any) => setPanelPricePerHour(e.target.value)}></Input>
              </div>
            </div>
        )}
        {buildingId == "262" && title == "High level Quarterly ceiling dusting cleaning" && (
            <div className={styles.btnContainer} style={{width: '100%'}}>
              <div style={{width: '33%'}}>
                <span style={{marginBottom: '5px'}}>Ceiling dusting per hour (£):</span>
                <Input style={{marginTop: '10px'}} value={dustingPricePerHour} type={"number"} onChange={(e:any) => setDustingPricePerHour(e.target.value)}></Input>
              </div>
            </div>
        )}
        <Table
          className={styles.table}
          size="small"
          dataSource={rows?.filter(x => x.cells && x.cells.length > 0).map((row: IRow, index: number) => {
            const rowProps: any = {};
            headers?.forEach((col: IHeader, indx: number) => {
              const cellValue = row.cells?.[indx].data?.formattedValue;
              rowProps[col.name as string] = 
                  cellValue?.replace("{{carpetCleaningPricePerUnit}}", ((carpetCleaningPricePerUnit ?? 0).toLocaleString()))
                            .replace("{{carpetCleaningFrequency}}", (capitalizeFirstLetter(carpetCleaningFrequency)))
                            .replace("{{carpetCleaningPrice}}", (calculateCarpetPrice().toLocaleString() + " £"))
                            .replace("{{hardFloorCleaningPricePerUnit}}", ((hardFloorCleaningPricePerUnit ?? 0)).toLocaleString())
                            .replace("{{hardFloorFrequency}}", (capitalizeFirstLetter(hardFloorCleaningFrequency)))
                            .replace("{{hardFloorCleaningPrice}}", (calculateHardfloorPrice().toLocaleString() + " £"))
                            .replace("{{totalCostDoors}}", ((doorPricePerHour ?? 0) * 2.6 * 12).toLocaleString() + " £")
                            .replace("{{totalCostPanels}}", ((panelPricePerHour ?? 0) * 4.651 * 12).toLocaleString() + " £")
                            .replace("{{totalCostDusting}}", ((dustingPricePerHour ?? 0) * 21.5 * 4).toLocaleString() + " £");
            });
            return {
              key: index,
              ...rowProps,
            };
          })}
          columns={
            headers
              ?.filter(
                (col: IHeader, index: number) =>
                  index === 0 || col.name === activeOption
              )
              .map((col: IHeader, index: number) => {
                return {
                  title: col.name,
                  dataIndex: col.name,
                  key: col.name ?? index,
                  className:
                    col.name === activeOption
                      ? columnStyle(activeOption ?? "")
                      : "",
                  hidden: !col.isVisible,
                  width: "300px",
                };
              }) as ColumnsType<any>
          }
          pagination={false}
        />
      </div>
      <div className={styles.bodyContainer} style={{flex: "0 0 100%"}}>
        {title && <h3>{title}</h3>}
        {buildingId == "262" && title == "Periodics" && (
            <div className={styles.btnContainer} style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                <div style={{width: '25%', display: 'flex', flexDirection: 'column'}}>
                    <span>Carpet cleaning per m2 (£):</span>
                    <Input style={{marginTop: '10px'}} value={carpetCleaningPricePerUnit} type={"number"}
                           onChange={(e: any) => setCarpetCleaningPricePerUnit(e.target.value)}></Input>
                </div>
                <div style={{width: '25%', display: 'flex', flexDirection: 'column'}}>
                    <span>Carpet cleaning frequency:</span>
                    <Select style={{marginTop: '10px'}} value={carpetCleaningFrequency}
                            onChange={(value: any) => setCarpetCleaningFrequency(value)}
                            options={[
                                {value: 'daily', label: 'Daily'},
                                {value: 'weekly', label: 'Weekly'},
                                {value: 'biweekly', label: 'Bi-Weekly'},
                                {value: 'monthly', label: 'Monthly'},
                                {value: 'quarterly', label: 'Quarterly'},
                                {value: 'biannually', label: 'Bi-Annually'},
                                {value: 'annually', label: 'Annually'},
                            ]}>
                    </Select>
                </div>
                <div style={{width: '25%', display: 'flex', flexDirection: 'column'}}>
                    <span>Hard floor cleaning per m2 (£):</span>
                    <Input style={{marginTop: '10px'}} value={hardFloorCleaningPricePerUnit} type={"number"}
                           onChange={(e: any) => setHardFloorCleaningPricePerUnit(e.target.value)}></Input>
                </div>
                <div style={{width: '25%', display: 'flex', flexDirection: 'column'}}>
                    <span>Hard floor cleaning frequency:</span>
                    <Select style={{marginTop: '10px'}} value={hardFloorCleaningFrequency}
                            onChange={(value: any) => setHardFloorCleaningFrequency(value)}
                            options={[
                                {value: 'daily', label: 'Daily'},
                                {value: 'weekly', label: 'Weekly'},
                                {value: 'biweekly', label: 'Bi-Weekly'},
                                {value: 'monthly', label: 'Monthly'},
                                {value: 'quarterly', label: 'Quarterly'},
                                {value: 'biannually', label: 'Bi-Annually'},
                                {value: 'annually', label: 'Annually'},
                            ]}>
                    </Select>
                </div>
            </div>
        )}
          {buildingId == "262" && title == "Internal glass monthly cleaning" && (
              <div className={styles.btnContainer} style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                  <div style={{width: '33%'}}>
                      <span>Door price per hour (£):</span>
                      <Input style={{marginTop: '10px'}} value={doorPricePerHour} type={"number"}
                             onChange={(e: any) => setDoorPricePerHour(e.target.value)}></Input>
                  </div>
                  <div style={{width: '33%'}}>
                      <span style={{marginBottom: '5px'}}>Glass panel price per hour (£):</span>
                      <Input style={{marginTop: '10px'}} value={panelPricePerHour} type={"number"}
                             onChange={(e: any) => setPanelPricePerHour(e.target.value)}></Input>
                  </div>
              </div>
          )}
          {buildingId == "262" && title == "High level Quarterly ceiling dusting cleaning" && (
              <div className={styles.btnContainer} style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                  <div style={{width: '33%'}}>
                      <span style={{marginBottom: '5px'}}>Ceiling dusting per hour (£):</span>
                      <Input style={{marginTop: '10px'}} value={dustingPricePerHour} type={"number"} onChange={(e:any) => setDustingPricePerHour(e.target.value)}></Input>
              </div>
            </div>
        )}
        <Table
          className={styles.table}
          size="small"
          dataSource={rows?.filter(x => x.cells && x.cells.length > 0).map((row: IRow, index: number) => {
            const rowProps: any = {};
            headers?.forEach((col: IHeader, indx: number) => {
              const cellValue = row.cells?.[indx].data?.formattedValue;
              rowProps[col.name as string] =
                  cellValue?.replace("{{carpetCleaningPricePerUnit}}", ((carpetCleaningPricePerUnit ?? 0).toLocaleString()))
                      .replace("{{carpetCleaningFrequency}}", (capitalizeFirstLetter(carpetCleaningFrequency)))
                      .replace("{{carpetCleaningPrice}}", (calculateCarpetPrice().toLocaleString() + " £"))
                      .replace("{{hardFloorCleaningPricePerUnit}}", ((hardFloorCleaningPricePerUnit ?? 0)).toLocaleString())
                      .replace("{{hardFloorFrequency}}", (capitalizeFirstLetter(hardFloorCleaningFrequency)))
                      .replace("{{hardFloorCleaningPrice}}", (calculateHardfloorPrice().toLocaleString() + " £"))
                      .replace("{{totalCoxstDoors}}", ((doorPricePerHour ?? 0) * 2.6 * 12).toLocaleString() + " £")
                      .replace("{{totalCostPanels}}", ((panelPricePerHour ?? 0) * 4.651 * 12).toLocaleString() + " £")
                      .replace("{{totalCostDusting}}", ((dustingPricePerHour ?? 0) * 21.5 * 4).toLocaleString() + " £");
            });
            return {
              key: index,
              ...rowProps,
            };
          })}
          columns={
            headers?.map((col: IHeader, index: number) => {
              return {
                title: col.name,
                dataIndex: col.name,
                key: col.name ?? index,
                className:
                  col.name === activeOption
                    ? columnStyle(activeOption ?? "")
                    : "",
                hidden: !col.isVisible,
                width: "300px",
              };
            }) as ColumnsType<any>
          }
          pagination={false}
        />
      </div>
    </>
  );
};

export default ScreenTable;

const columnStyle = (title: string) => {
  switch (title) {
    case "Premium":
      return styles.activePremium;
    case "Optimal":
      return styles.activeOptimal;
    case "Basic":
      return styles.activeBasic;
    case "Custom":
      return styles.activeCustom;
    default:
      return styles.activePremium;
  }
};
