import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getBenchmark: any = createAsyncThunk(
  "getBenchmark",
  async ({
    buildingId,
    isDemo,
  }: {
    buildingId: string | number;
    isDemo?: boolean | null;
  }) => {
      if (buildingId == 275) {
          const response = await axios.get(
              `https://scanquo-platform-api.azurewebsites.net/benchmark/get5/${buildingId}/v2${
                  isDemo ? "?demo=true" : ""
              }`
          );
          return response;
      }
      const response = await axios.get(
  `https://scanquo-platform-api.azurewebsites.net/benchmark/get/${buildingId}${
        isDemo ? "?demo=true" : ""
      }`
    );
    return response;
  }
);

export const getFRRatings: any = createAsyncThunk(
    "getFRRatings",
    async ({
               buildingId,
           }: {
        buildingId: string | number;
    }) => {
        const response = await axios.get(
            `https://scanquo-platform-api.azurewebsites.net/benchmark/getfrratings/${buildingId}`
        );
        return response;
    }
);

export const authBenchmark: any = createAsyncThunk(
    "authBenchmark",
    async ({
             buildingId,
             password,
           }: {
      buildingId: string | number;
      password: string;
    }) => {
      const response = await axios.post(
          `http://localhost:5057/benchmark/authorize?${buildingId}`, password);
              // `https://scanquo-platform-api.azurewebsites.net/benchmark/get/${buildingId}`, password);
      return response;
    }
);
