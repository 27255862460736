import { Space, Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  IAsset,
  IAssetCleaningTask,
  IFloor,
  IRoom,
} from "../../../../models/floorplan/Floorplan";
import { IScanResponse } from "../../../../models/scans/ScanResponse";
import AssetCleaningTasksService from "../../../../services/assetcleaningtasks/AssetCleaningTasksService";
import BuildingsService from "../../../../services/buildings/BuildingsService";
import FloorsService from "../../../../services/floors/FloorsService";
import MatterportSpacesService from "../../../../services/import/MatterportSpacesService";
import { extractMatterportFileId } from "../../../../utils/miscellaneous";
import { RoomMapping } from "../../../platform/shared/roomMapping/RoomMapping";
import kingGerogeGroundFloor from "../../../../img/king-george/GroundFloor.png";
import kingGerogeFirstFloor from "../../../../img/king-george/1stFloor.png";
import kingGerogeSecondFloor from "../../../../img/king-george/2ndFloor.png";

const emptyFloor: IFloor = {
  id: 0,
  name: "",
  rooms: [],
  key: 0,
  imageComponent: "",
};

const FloorMapping = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFloorId, setSelectedFloorId] = useState<number | null>(null);
  const [selectedFloor, setSelectedFloor] = useState<IFloor | null>(null);
  const [floors, setFloors] = useState<IFloor[] | null>(null);
  const [rooms, setRooms] = useState<IRoom[] | null>(null);
  const [assets, setAssets] = useState<IAsset[] | null>(null);
  const [floorplanImgUrl, setFloorplanImgUrl] = useState<string | null>(null);
  const [matterportFileIds, setMatterportFileIds] = useState<string[] | null>(
    null
  );
  const [buildingScans, setBuildingScans] = useState<IScanResponse[] | null>(
    null
  );
  const [loadedScan, setLoadedScan] = useState<IScanResponse | null>(null);
  const [assetCleaningTasks, setAssetCleaningTasks] = useState<
    IAssetCleaningTask[] | null
  >();
  const { buildingId } = useParams();

  useEffect(() => {
    if (!buildingId) return;
    setLoading(true);
    BuildingsService.get(buildingId).then((response: any) => {
      const building = response.data;
      if (building.floors.length > 0) {
        setSelectedFloorId(building.floors[0].id ?? 0);
        setFloors(building.floors ?? []);
        setRooms(building.floors?.flatMap((f: any) => f.rooms) ?? []);
        setLoadedScan(
          building.scans?.find(
            (scan: IScanResponse) => scan.id === building.floors[0].scanId
          ) ?? {}
        );
        FloorsService.get(building.floors[0].id).then((response: any) => {
          if (response.data) {
            response.data.rooms = response.data.rooms.map((x:any) => ({
              ...x,
              roomAssetCleaningTasks: (x.roomAssetCleaningTasks == null || x.roomAssetCleaningTasks.length == 0) && 
                x.roomAssetCleaningTasksV2 != null && x.roomAssetCleaningTasksV2.length > 0 ? x.roomAssetCleaningTasksV2 : x.roomAssetCleaningTasks
            }));
          }
          setSelectedFloor(response.data);
        });
      } else {
        setSelectedFloorId(0);
        setSelectedFloor(emptyFloor);
        setFloors([]);
        setRooms([]);
        setLoadedScan({});
      }
      setBuildingScans(building.scans ?? []);

      AssetCleaningTasksService.get(building.buildingTypeId).then(
        (response: any) => {
          setAssets(
            response.data
              .map((a: IAssetCleaningTask) => a.asset)
              .filter(
                (item: IAsset, index: number, self: any) =>
                  self.findIndex((s: IAsset) => item.id === s.id) === index
              ) ?? []
          );
          setAssetCleaningTasks(
            response.data.filter(
              (a: IAssetCleaningTask) =>
                a.asset &&
                a.roomType &&
                a.assetMaterial &&
                a.assetSize &&
                a.cleaningTask
            ) ?? []
          );
        }
      );
    });
  }, [buildingId]);
  
  const getFloorplanImg = (buildingId: string, scanSequence: number | null | undefined) => {
    if (buildingId === "251") { // Beacon (Brendon)
      if (scanSequence === 0) {
        setFloorplanImgUrl("https://i.postimg.cc/sXnj7cSQ/beacon-floor-1.png");
      }
      else if (scanSequence === 1) {
        setFloorplanImgUrl("https://i.postimg.cc/Pf9hHNMj/beacon-floor-2.png");
      }
      else if (scanSequence === 2) {
        setFloorplanImgUrl("https://i.postimg.cc/x8wS3FdC/beacon-floor-3.png");
      }
      else if (scanSequence === 3) {
        setFloorplanImgUrl("https://i.postimg.cc/C5BgDVSX/beacon-floor-4.png");
      }
    }
    if (buildingId === "261") { // Dewsbury (Mitie)
      if (scanSequence === 0) {
        setFloorplanImgUrl("https://i.postimg.cc/7LmkzQpS/dewsbury-lower-ground.png\n");
      }
      if (scanSequence === 1) {
        setFloorplanImgUrl("https://i.postimg.cc/DwQFvz6j/dewsbury-ground.png");
      }
      if (scanSequence === 2) {
        setFloorplanImgUrl("https://i.postimg.cc/GpcRH1Sc/dewsbury-first-floor.png");
      }
    }

    if (buildingId === "262") { // CBRE
      if (scanSequence === -1) { // Lower ground floor
        setFloorplanImgUrl("https://i.postimg.cc/jdKVZSxn/CBRE-Ground-floor.png");
      }
      if (scanSequence === 0) { // Ground floor
        setFloorplanImgUrl("https://i.postimg.cc/jdKVZSxn/CBRE-Ground-floor.png");
      }
      if (scanSequence === 1) { // Upper ground floor
        setFloorplanImgUrl("https://i.postimg.cc/2SpRCD2T/CBRE-Upper-ground-floor.png");
      }
      if (scanSequence === 2) { // 1st floor
        setFloorplanImgUrl("https://i.postimg.cc/KcnstMN7/CBRE-3rd-floor.png");
      }
      if (scanSequence === 3) { // 2nd floor
        setFloorplanImgUrl("https://i.postimg.cc/KcnstMN7/CBRE-3rd-floor.png");
      }
      if (scanSequence === 4) { // 3rd floor
        setFloorplanImgUrl("https://i.postimg.cc/KcnstMN7/CBRE-3rd-floor.png");
      }
      if (scanSequence === 5) { // 4th floor
        setFloorplanImgUrl("https://i.postimg.cc/SxwWZZQn/CBRE-6th-floor.png");
      }
      if (scanSequence === 6) { // 5th floor
        setFloorplanImgUrl("https://i.postimg.cc/SxwWZZQn/CBRE-6th-floor.png");
      }
      if (scanSequence === 7) { // 6th floor
        setFloorplanImgUrl("https://i.postimg.cc/SxwWZZQn/CBRE-6th-floor.png");
      }
      if (scanSequence === 8) { // 7th floor
        setFloorplanImgUrl("https://i.postimg.cc/bYT9zHg5/CBRE-7th-floor.png");
      }
    }
    
    if (buildingId === "275") { // King George
      if (scanSequence === 0) {
        setFloorplanImgUrl(kingGerogeGroundFloor);
      }
      if (scanSequence === 1) {
        setFloorplanImgUrl(kingGerogeFirstFloor);
      }
      if (scanSequence === 2) {
        setFloorplanImgUrl(kingGerogeSecondFloor);
      }
    }
    

    //Ground floor = 559 
    //Upper ground floor = 559 + 559,2 =
    // 1st,2nd, floor = 653 x 3 =  1959m2
    // 4th, 5th, 6th floor = 663 x 3 = 1989m2 
    // 7th floor = 339,3m2


  }

  useEffect(() => {
    if (buildingScans && buildingScans.length > 0 && selectedFloor) {
      // If the selectedFloor doesn't belong under loadedScan, load the appropriate scan
      if (selectedFloor?.scanId && selectedFloor?.scanId !== loadedScan?.id) {
        const newLoadedScan = buildingScans.find(
          (scan) => scan.id === selectedFloor?.scanId
        );
        if (buildingId === "251" || buildingId === "261" || buildingId === "262" || buildingId === "275") {
          getFloorplanImg(buildingId, selectedFloor.scanSequence)
          return;
        }
        const matterportFileId = extractMatterportFileId(newLoadedScan);
        if (matterportFileId) {
          MatterportSpacesService.get(matterportFileId).then(
            (response: any) => {
              setLoadedScan(newLoadedScan ?? {});
              if (response.data?.assets?.floorplans) {
                const colorplanMediaAssets =  
                  response.data.assets.floorplans.filter((f: any) =>
                    f.url.includes("colorplan_alpha_00")
                  );

                const floorplanMediaAssets =
                  colorplanMediaAssets.find(
                    (f: any) =>
                      f.url.includes(`colorplan_alpha_00${selectedFloor.scanSequence}.png`)
                  ) ?? colorplanMediaAssets[0];
                if (floorplanMediaAssets) {
                  //TODO check validUnitl field
                  setFloorplanImgUrl(floorplanMediaAssets.url);
                } else setFloorplanImgUrl("");
              } else setFloorplanImgUrl("");
            }
          );
        }
      } else if (loadedScan) {
        if (buildingId === "251" || buildingId === "261" || buildingId === "262" || buildingId === "275") {
          getFloorplanImg(buildingId, selectedFloor.scanSequence)
          return;
        }
        const matterportFileId = extractMatterportFileId(loadedScan);
        if (matterportFileId) {
          MatterportSpacesService.get(matterportFileId).then(
            (response: any) => {
              if (response.data?.assets?.floorplans) {
                const colorplanMediaAssets =
                  response.data.assets.floorplans.filter((f: any) =>
                    f.url.includes("colorplan_alpha_00")
                  );

                const floorplanMediaAssets =
                  colorplanMediaAssets.find(
                    (f: any) =>
                      f.url.includes(`colorplan_alpha_00${selectedFloor.scanSequence}.png`)
                  ) ?? colorplanMediaAssets[0];
                if (floorplanMediaAssets) {
                  //TODO check validUnitl field
                  setFloorplanImgUrl(floorplanMediaAssets.url);
                } else setFloorplanImgUrl("");
              } else setFloorplanImgUrl("");
            }
          );
        }
      }
    }
  }, [buildingScans, loadedScan, selectedFloor]);

  useEffect(() => {
    if (selectedFloorId === null) return;
    else if (selectedFloor === null) return;
    else if (floors === null) return;
    else if (rooms === null) return;
    else if (assets === null) return;
    else if (floorplanImgUrl === null) return;
    else if (assetCleaningTasks === null) return;
    else if (buildingScans === null) return;

    setLoading(false);
  }, [
    selectedFloorId,
    selectedFloor,
    floors,
    rooms,
    assets,
    floorplanImgUrl,
    assetCleaningTasks,
    matterportFileIds,
    buildingScans,
  ]);

  const handleFloorChange = (floorId: number) => {
    if (!floorId) {
      console.error("Invalid floor id: ", floorId);
      return;
    }

    FloorsService.get(floorId)
      .then((floorRespoonse) => {
        if (floorRespoonse.status >= 300) {
          console.error("floorRespoonse", floorRespoonse);
          return;
        }

        const floor = floorRespoonse?.data ?? emptyFloor;
        
        if (floor) {
          floor.rooms = floor.rooms.map((x:any) => ({
            ...x,
            roomAssetCleaningTasks: (x.roomAssetCleaningTasks == null || x.roomAssetCleaningTasks.length == 0) && 
              x.roomAssetCleaningTasksV2 != null && x.roomAssetCleaningTasksV2.length > 0 ? x.roomAssetCleaningTasksV2 : x.roomAssetCleaningTasks
          }));
        }
        
        setSelectedFloor(floor);
        setSelectedFloorId(floor?.id ?? 0);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      {loading && (
        <Spin
          style={{
            transform: "translate(-50%, -50%)",
            left: "calc(50vw + 125px)",
            top: "50vh",
            position: "fixed",
          }}
        />
      )}
      {!loading && (
        <>
          {buildingId &&
          floors &&
          rooms &&
          assets &&
          assetCleaningTasks &&
          selectedFloorId &&
          selectedFloor &&
          floorplanImgUrl ? (
            <RoomMapping
              buildingId={buildingId}
              floors={floors}
              rooms={rooms}
              assets={assets}
              assetCleaningTasks={assetCleaningTasks}
              selectedFloorId={selectedFloorId}
              selectedFloor={selectedFloor}
              floorplanImgUrl={floorplanImgUrl}
              matterportLink={
                buildingScans?.find((scan) => scan.id === selectedFloor.scanId)
                  ?.benchmarkRawFootageLink
              }
              config={{
                editable: true,
                floorSelect: true,
                enableVirtualWalk: true,
              }}
              handleActiveFloorChange={handleFloorChange}
            />
          ) : (
            <Space
              style={{
                height: "100%",
                width: "100%",
                justifyContent: "center",
                fontSize: "16px",
              }}
              align="center"
            >
              <label>No Floorplan Scan Data</label>
            </Space>
          )}
        </>
      )}
    </>
  );
};

export default FloorMapping;
